import React from "react";
import { CogIcon, AcademicCapIcon } from "@heroicons/react/24/outline";
import Languages from "./Languages";
import { Fade } from "react-awesome-reveal";

const Skills = () => {
  const frameworks = [
    "React",
    "TailwindCSS",
    "Angular",
    "Flask",
    "Bootstrap",
    "Tensorflow",
    "Keras",
    "Pytorch",
    "Pandas",
    "MySQL",
    "PostgreSQL",
    "Docker",
  ];
  const knowledge = [
    "Machine Learning",
    "Cloud Computing",
    "RESTful APIs",
    "Virtualization",
    "CI/CD",
    "Agile Methodology",
    "Operating Systems",
    "User Experience",
    "Software Testing",
    "Problem Solving",
    "Data Analytics",
    "Hardware",
  ];

  return (
    <div
      className="bg-white min-h-screen flex justify-center items-center mt-10"
      id="about"
    >
      <div className="max-w-6xl mx-auto px-4 my-10  ">
        <div className="text-center mb-10 w-10/12 mx-auto">
          <h2 className="text-3xl font-bold ">My Skills</h2>

          <p className=" mt-2">
            Throughout my degree in IT, my professional work experience and my
            personal projects, I've had a great deal of exposure to a number of
            languages and frameworks. Listed are the ones I've used in a
            production environment, or have been using for a number of years.
          </p>
        </div>
        <Languages />

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 text-center mt-10">
          <Fade cascade>
            {/* Frameworks */}
            <div className="p-4 shadow-lg rounded-lg bg-blue-200">
              <h3 className="text-xl font-semibold mb-2 flex items-center justify-center">
                <CogIcon className="h-6 w-6 mr-2" />
                Frameworks
              </h3>
              <ul>
                {frameworks.map((framework, index) => (
                  <li key={index}>{framework}</li>
                ))}
              </ul>
            </div>
            {/* Knowledge */}
            <div className="p-4 shadow-lg rounded-lg bg-blue-200">
              <h3 className="text-xl font-semibold mb-2 flex items-center justify-center">
                <AcademicCapIcon className="h-6 w-6 mr-2" />
                Knowledge
              </h3>
              {knowledge.map((know, index) => (
                <div key={index} className="knowledge-item">
                  {know}
                </div>
              ))}
            </div>
          </Fade>
        </div>
        <div className="flex justify-center mt-16">
          <a
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-700 rounded "
            href="#timeline"
          >
            My Journey
          </a>
        </div>
      </div>
    </div>
  );
};

export default Skills;
